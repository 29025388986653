<script>
import APIUser from '@app/services/API/User';

export default {
  name: 'AffiliationAffiliates',
  head: {
    title: 'Mes affilités - Affiliation',
  },
  data: () => ({
    isFetching: false,
    godchildren: [],
  }),
  computed: {
    authUser() {
      return this.$store.getters['auth/user'];
    },
  },
  created() {
    this.isFetching = true;
    APIUser.getGodchildren()
      .then(({ data }) => (this.godchildren = data))
      .finally(() => (this.isFetching = false));
  },
};
</script>

<template>
  <section class="acomp">
    <div class="box">
      <b-table :data="godchildren" :loading="isFetching">
        <b-table-column cell-class="py-5" v-slot="{ row }" field="store_name" label="Formateur" sortable>
          <div>
            {{ row.store_name }} <br>
            <a :href="`//${row.store_subdomain}.${$env.rootHost}`" target="_blank">
              {{ row.store_subdomain }}.{{ $env.rootHost }}
            </a>
          </div>
        </b-table-column>
        <b-table-column cell-class="acomp_pack py-5" v-slot="{ row }" field="plan" label="Pack" sortable>
          <span v-t="`plans.${row.plan}`" />
        </b-table-column>
        <b-table-column cell-class="py-5 is-size-8" v-slot="{ row }" field="created_at" label="Inscrit" sortable numeric>
          <b-tooltip :label="$moment.utc(row.created_at).local().format('DD/MM/YY [à] HH:mm')">
            {{ row.created_at | momentFromUTC | moment('from') }}
          </b-tooltip>
        </b-table-column>

        <template #empty v-if="!isFetching">
          Vous n'avez pas encore d'affiliés.
        </template>
      </b-table>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.acomp {
  ::v-deep &_pack {
    max-width: 200px;
    word-wrap: break-word !important;
    word-break: break-word !important;
  }

  @include mobile {
    ::v-deep &_pack {
      max-width: none;
    }
  }
}
</style>
