import { render, staticRenderFns } from "./Affiliates.vue?vue&type=template&id=29518bea&scoped=true&"
import script from "./Affiliates.vue?vue&type=script&lang=js&"
export * from "./Affiliates.vue?vue&type=script&lang=js&"
import style0 from "./Affiliates.vue?vue&type=style&index=0&id=29518bea&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29518bea",
  null
  
)

export default component.exports